@media all and (max-width: 980px) {
  .About {
    margin-top: 10%;
    color: white;
    .About-1 {
      display: flex;
      flex-direction: column;
      .About-1-content {
        width: 100% !important;
        display: flex;
        flex-direction: column;
      }
      img {
        margin-top: 8%;
        width: 100% !important;
        height: 100% !important;
      }
    }
    .About-2 {
      animation: 3.5s ease-out 0s 1 slideInFromRight;

      .elementor-container {
        display: flex;
        flex-direction: column;
        .elementor-container-deploy,
        .elementor-container-coinMarketCap,
        .elementor-container-more {
          div {
            text-align: center;
          }
          margin-top: 5%;
          width: 100% !important;
        }
      }
    }

    .About-2dad {
      display: flex;
      flex-direction: column;
      animation: slideIn 4.5s ease-out forwards;
      .About-2B {
        width: 100% !important;
        margin-top: 6% !important;
      }
    }

    .About-3 {
      .About-3-1 {
        display: flex;
        flex-direction: column;
        h3 {
          text-align: left !important;
          margin-top: -8%;
          margin-bottom: 15%;
        }
      }
      .About-3-2 {
        margin-top: 2% !important;
        display: flex;
        flex-direction: column;
        h3 {
          text-align: left !important;
          margin-top: -8%;
          margin-bottom: 15%;
        }
      }
    }

    .About-4 {
      display: flex;
      flex-direction: column;
      height: 860px !important;
      div {
        width: 60% !important;
      }
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.About-1 {
  animation: slideIn 1.5s ease-out forwards;
}

.About {
  margin-top: 10%;
  color: white;
  .About-1 {
    display: flex;
    justify-content: space-between;
    img {
      border-radius: 50px;
    }
    .About-1-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 60px;
      }
      h3 {
        margin-top: 3%;
      }
      .About-1-button {
        button {
          margin-top: 3%;
          border-radius: 15px;
          background-color: rgb(255, 165, 0);
          color: white;
          margin-right: 8%;
          padding: 1%;
        }
      }
    }
    img {
      width: 40%;
      height: 38%;
    }
  }

  .About-2 {
    margin-top: 16%;
    margin-bottom: 18%;
    animation: 3.5s ease-out 0s 1 slideInFromRight;
    h1 {
      margin-bottom: 10%;
      text-align: center;
      font-size: 3rem;
    }
    .elementor-container {
      display: flex;
      justify-content: space-between;
      .elementor-container-deploy,
      .elementor-container-coinMarketCap,
      .elementor-container-more {
        div {
          text-align: center;
        }
        width: 25%;
        background-color: #4d648d;
        border-radius: 20px;
      }

      li {
        margin-top: 5%;
      }
    }
  }

  .About-2dad {
    display: flex;
    justify-content: space-between;
    animation: slideIn 4.5s ease-out forwards;
    .About-2B {
      width: 25%;
      border: 1px solid #28dbd1;
      border-radius: 6px;
      margin-top: 16px;
      padding: 16px;
      img {
        width: 100%;
        height: 8rem;
        margin: 0 auto;
      }
      .About-2B-a {
        display: flex;
      }
      .About-2B2 {
        .About-2B-Offered,
        .About-2B-Vesting {
          display: flex;
          justify-content: space-between !important;
          div {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .NEP {
    margin-top: 6%;
    font-size: 40px;
  }

  .About-3 {
    animation: slideIn 6.5s ease-out forwards;
    margin-bottom: 8%;
    h1 {
      margin-top: 6%;
      font-size: 60px;
    }
    .About-3-1 {
      margin-top: 10%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      h3 {
        text-align: center;
      }
    }
    .About-3-2 {
      margin-top: 10%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      h3 {
        text-align: center;
      }
    }
  }

  .About-4 {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    height: 260px;
    div {
      width: 10%;
      a {
        text-decoration: none;
        font-size: large;
        color: white;
      }
      div {
        font-size: 18px;
        margin-top: 2%;
      }
      h3,
      h4 {
        color: #86868b;
      }
      h6 {
        color: #86868b;
      }
      h5 {
        margin-top: 13%;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .ido {
    color: white;
    display: flex;
    flex-direction: column;
    height: 1200px !important;
    .ido-detal {
      width: 100% !important;
    }
    .ido-page {
      width: 100% !important;
      margin-top: 10%;
    }
  }
  .chart {
    margin-top: 30% !important;
    display: flex;
    flex-direction: column;
    .chart-tokendetail {
      width: 100% !important;
      margin-bottom: 15%;
      h6 {
        font-size: 1rem;
      }
    }

    .chart-ido {
      width: 100% !important;
    }
  }
}

.ido-one {
  color: white;
}
.ido {
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 6%;
  width: 100%;
  height: 600px;
  background-image: none;
  .ido-detal {
    width: 60%;
    display: flex;
    flex-direction: column;

    background-image: none;
    .text {
      background-image: none;
    }
    img {
      aspect-ratio: 16/5;
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
    .ido-img {
      display: flex;
      align-items: flex-start;
      margin-top: 3%;
      margin-bottom: 3%;
      background-image: none;
    }
    .crx {
      height: 100px;
      width: 100px;
      background-image: none;
      //   background-color: none
    }
  }
  .ido-page {
    // margin-right: -10%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.07),
      rgba(255, 255, 255, 0.07)
    );
    // background-color: rgb(90 18 47 / 20%);
    border-radius: 25px;
    .time-end {
      background-image: none;
      margin-top: 5%;
      margin-bottom: -5%;
      width: 80%;
    }

    .Sale-Type,
    .Current-Rate,
    .Current-Raised,
    .Current-Listing {
      margin-top: 5%;
      display: flex;
      justify-content: space-between;
      width: 80%;
    }
    // justify-content: space-between;

    button {
      margin-top: 5%;
    }

    .button-view {
      color: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      padding: 5px;
      border: 1px solid none;
      font-size: large;
    }
    .your-nep {
      margin-top: 8%;
    }
  }
}

.chart {
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  .chart-tokendetail {
    width: 60%;
    .token-detail {
      // width: 60%;
      .token-detail-1 {
        margin-top: 1%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media all and (max-width: 600px) {
  .Nav-link {
    .nav-link-page {
      display: flex;
    }
    a {
      color: white;
      margin-right: 10% !important;
      font-size: 1.2rem !important;
    }

    button {
      border-radius: 8px;
      padding: 1px !important;
    }
  }
}

.Nav-link {
  display: flex;
  margin-top: 2%;
  justify-content: space-between;
  .nav-link-page {
    display: flex;
  }

  button {
    border-radius: 8px;
    padding: 5px;
    font-size: large;
    border-color: transparent;
  }
  a {
    color: white;
    margin-right: 30%;
    text-decoration: none;
    font-size: 1.5rem;
  }
}

.active {
  background: none;
}

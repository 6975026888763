.ido-time {
  //   background-color: aquamarine;
  h2 {
    text-align: center;
  }
  .count-time {
    display: flex;
  }
  .process-1 {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  div {
    input {
      width: 100%;
      border-radius: 10px;
      border: none;
    }
  }
  button {
    width: 30%;
    border-radius: 10px;
    background-color: #51adf6;
    border: none;
  }
}

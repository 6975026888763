body {
  margin: 0;
  padding: 0;
  background: linear-gradient(
    69.48deg,
    rgb(14, 25, 45) 21.6%,
    rgb(25, 41, 74) 85.56%
  );
  .App-header {
    width: 75%;
    margin: auto;
    font-size: x-large;
    background-image: none;
  }
}
* {
  font-family: "Manrope-Bold";
  margin: 0;
  padding: 0;
}

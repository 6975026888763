.Create {
  margin-top: 6%;
  margin-left: 20%;
  color: white;
  width: 60%;
  //   background-color: gray;
  h1 {
    font-size: 40px;
    margin-bottom: 5%;
  }
  input {
    width: 100%;
    border-radius: 10px;
    margin-top: 1%;
    margin-bottom: 2%;
    background-color: #2d293a;
    color: white;
  }
  .Project {
    margin-top: 15%;
  }
  button {
    border-radius: 10px;
    padding: 2px 26px;
    margin-left: 80%;
    background-color: #748eed;
  }
}
